import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import { useHistory } from 'react-router';
import SvgFire from '../components/SvgFire';
import { g_SiteContent, HeroSection, Layout } from '../data/contentTypes';
import './Home.css';

import { useLayoutEffect, useState, useEffect } from 'react';
import SimonIcon from '../components/SimonIcon';

const fullName: string = g_SiteContent.Hero.name;
const names: string[] = fullName.split(" ", 2);

const landscapeCoords =
  [
    { x: 450, y: 100 },
    { x: 600, y: 100 },
    { x: 450, y: 250 },
    { x: 600, y: 250 }
  ];

const portraitCoords =
  [
    { x: 100, y: 450 },
    { x: 250, y: 450 },
    { x: 100, y: 600 },
    { x: 250, y: 600 }
  ];


const Home: React.FC = () => {
  const history = useHistory();
  const [screenWidth, setScreenWidth] = useState(800);
  const [screenHeight, setScreenHeight] = useState(600);

  const sections: HeroSection[] = g_SiteContent.Hero.sections;

  function navToSection(i: number, section: HeroSection) {
    if (section.link != undefined) {
      // navigate to link
      window.location.href = section.link;
      return;
    }

    history.push("/section/" + i);
  }

  function updateScreenSize() {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    updateScreenSize();
    return (() => window.removeEventListener("resize", updateScreenSize));
  });


  const svgWidth: number = (screenWidth < 768) ? 450 : 800;
  const svgHeight: number = (screenWidth < 768) ? 800 : 450;
  const viewboxStr: string = `0 0 ${svgWidth} ${svgHeight}`;

  const sectionItems = sections.map((section, index) => {
    if (index < portraitCoords.length) {
      return (
        <g key={index}>
          <g transform={`translate(${landscapeCoords[index].x}, ${landscapeCoords[index].y})`} onClick={() => navToSection(index, section)}>
            <rect className="box" width="100" height="100" x="0" y="0" />
            <text className="box normal" x="50" y="50">{section.shortTitle}</text>
            {(section.link) ? <text className="box subtle" x="50" y="85">{section.subtitle}</text> : ""}
          </g>

          <g transform={`translate(${portraitCoords[index].x}, ${portraitCoords[index].y})`} onClick={() => navToSection(index, section)}>
            <rect className="box" width="100" height="100" x="0" y="0" />
            <text className="box normal" x="50" y="50">{section.shortTitle}</text>
            {(section.link) ? <text className="box subtle" x="50" y="85">{section.subtitle}</text> : ""}
          </g>
        </g>
      );
    }
  });

  const [fireInitCount, setFireInitCount] = useState<number>(0);
  function reinitialiseFire() {
    setFireInitCount(fireInitCount + 1);
  }

  const layouts: Layout[] = g_SiteContent.layouts;

  function makeLayoutItems(layout: Layout) {
    return (
      <button key={layout.icon}>
        <a href={layout.link}>
          <p>{layout.name}</p>
          <SimonIcon icon={layout.icon} />
        </a>
      </button>

    )
  }

  const layoutItems = layouts.map(makeLayoutItems);

  return (
    <IonContent id="profile">
      <svg id="profile_svg" x="0" y="0" viewBox={viewboxStr} xmlns="http://www.w3.org/2000/svg">
        <SvgFire width={svgWidth} height={svgHeight} initCount={fireInitCount} />
        <g id="name" transform="translate(100, 100)" onClick={() => reinitialiseFire()}>
          <rect className="main" width="250" height="250" x="0" y="0"/>
          <text className="heading" x="125" y="80">{names[0]}</text>
          <text className="heading" x="125" y="150">{names[1]}</text>

          <image x="25" y="200" width="200" href="/assets/img/email_address.png" />
        </g>


        {sectionItems}

      </svg>
      <div className="background">
        {layoutItems}
      </div>
    </IonContent>
  );
};

export default Home;


import {content} from '../gen/content';


export interface HeroItem {
  title: string;
  text?: string;
  youtube?: string;
  link?: string;
  soundcloud?: number;
}

export interface HeroSubsection {
  title: string;
  image?: string;
  items: HeroItem[];
}

export interface HeroSection {
  shortTitle: string;
  title: string;
  subtitle: string;
  subsections?: HeroSubsection[];
  link?: string;
  thumb: string;
  hero_image?: string;
}


export interface Hero {
  name: string;
  email: string;
  sections: HeroSection[];
}

export interface Layout {
  name: string;
  link: string;
  icon: string;
}

export interface SiteContent {
  Hero: Hero;
  layouts: Layout[];
}

export var g_SiteContent : SiteContent = content;

export const getSiteContent = () => g_SiteContent;

export class Forest
{
    // 0 indicates blank
    // 1 indicates a tree
    // negative is fire
    trees: number[][] = [[]];
    width : number = 0;
    height: number = 0;

    constructor(nW: number, nH: number)
    {
        this.updateSize(nW, nH);
    }

    init(fillRatio: number)
    {
        for (let iX = 0; iX < this.width; iX++)
        {
            for (let iY = 0; iY < this.height; iY++)
            {
                this.trees[iX][iY] = (Math.random() < fillRatio) ? 1 : 0;
            }
        }      
        
        // for (let iY = 0; iY < this.height; iY++)
        // {
        //     this.trees[0][iY] = -1;
        // }
    }

    step()
    {
        let nextTrees : number[][] = [[]];

        for (let iX = 0; iX < this.width; iX++)
        {
            nextTrees[iX] = [];
            for (let iY = 0; iY < this.height; iY++)
            {
                let x = this.trees[iX][iY];
                let y = 0;
                let isUpFire = iY > 0 && this.trees[iX][iY - 1] == -1;
                let isDownFire = iY < (this.height - 1) && this.trees[iX][iY + 1] == -1;
                let isLeftFire = iX > 0 && this.trees[iX - 1][iY] == -1;
                let isRightFire = iX < (this.width - 1) && this.trees[iX + 1][iY] == -1;
                let isAnyFire = isUpFire || isDownFire || isLeftFire || isRightFire;

                if (x == 1) 
                {
                    y = (isAnyFire) ? -1: 1;
                }
                else if (x < 0 && x > -5)
                {
                    y = x - 1;
                }

                nextTrees[iX][iY] = y;
            }
        }    
        
        this.trees = nextTrees;
    }

    updateSize(nW: number, nH: number)
    {
        if (nW == this.width) return;
        if (nH == this.height) return;

        this.width = nW;
        this.height = nH;
        this.trees = [[]];
        for (let iX = 0; iX < nW; iX++)
        {
            this.trees[iX] = [];
            for (let iY = 0; iY < nH; iY++)
            {
                this.trees[iX][iY] = 0
            }
        }

        this.init(0.61);
    }

    burn(x: number, y: number)
    {
        if (x < 0) return;
        if (x >= this.width) return;
        if (y < 0) return;
        if (y >= this.height) return;

        this.trees[x][y] = -1;
    }
}

export var content = {
    "Hero": {
        "name": "Simon Parker",
        "email": "engineeringsimon@yahoo.com.au",
        "sections": [
            {
                "shortTitle": "Compose",
                "title": "Compositions",
                "subtitle": "Selection of music I've written.",
                "thumb": "compose_100x100.png",
                "hero_image": "compose_big.png",
                "subsections": [
                    {
                        "title": "Latest",
                        "items": [
                            {
                                "title": "The Golden Key",
                                "text": "Mockup from MuseScore 4 + my daughter narrating",
                                "youtube": "https://www.youtube.com/embed/S4eVHznsxcc"
                            }
                        ]
                    },
                    {
                        "title": "Contests",
                        "items": [
                            {
                                "title": "Ryan Leach Competition October (2022)",
                                "text": "The Man With Chairs for Hands",
                                "youtube": "https://www.youtube.com/embed/O_E23O_gsHk"
                            },
                            {
                                "title": "Spitfire Audio - Bridgerton (2022)",
                                "text": "Clarinet centric cue",
                                "youtube": "https://www.youtube.com/embed/Y7NRngEygy0"
                            },
                            {
                                "title": "Ryan Leach Competition July (2022)",
                                "text": "Return Home",
                                "youtube": "https://www.youtube.com/embed/dA5oR0ffk98"
                            },
                            {
                                "title": "Austin Wintory Rhythm Challenge (2021)",
                                "text": "Orchestral improvisation",
                                "youtube": "https://www.youtube.com/embed/9BDSOKq072U"
                            },
                            {
                                "title": "Spitfire Audio - Star Girl (2021)",
                                "youtube": "https://www.youtube.com/embed/Y10nzNNox68"
                            },
                            {
                                "title": "Spitfire Audio - Westworld (2020)",
                                "youtube": "https://www.youtube.com/embed/n7x6EY9QZeQ"
                            }
                        ]
                    },
                    {
                        "title": "Mucking Around",
                        "items": [
                            {
                                "title": "Kermit Saves the Day (2020)",
                                "youtube": "https://www.youtube.com/embed/MZXdqHq-oTc"
                            },
                            {
                                "title": "Mr. Tickle Saves the Day (2020)",
                                "youtube": "https://www.youtube.com/embed/sSdJz2KQ5A0"
                            }
                        ]
                    },
                    {
                        "title": "Wind Ensemble",
                        "items": [
                            {
                                "title": "The Golden Key",
                                "text": "Mockup from MuseScore 4 + my daughter narrating",
                                "youtube": "https://www.youtube.com/embed/S4eVHznsxcc"
                            },
                            {
                                "title": "Esk's Theme - Inaugural Performance (2019)",
                                "youtube": "https://www.youtube.com/embed/suIiEpLHdkg"
                            },
                            {
                                "title": "Mort Suite - Band (2019)",
                                "youtube": "https://www.youtube.com/embed/6-u86F3T00g"
                            },
                            {
                                "title": "Searching for Lambs (2016)",
                                "youtube": "https://www.youtube.com/embed/J3A7kfx5RWg"
                            },
                            {
                                "title": "Scuffy the Tugboat (2015)",
                                "text": "My first piece performed by a real Wind Ensemble (Inner West Community Band).",
                                "youtube": "https://www.youtube.com/embed/DCkbz35t4Ug"
                            },
                            {
                                "title": "Scuffy the Tugboat (2015)",
                                "text": "The world premier.",
                                "youtube": "https://www.youtube.com/embed/yA9Xvweecyc"
                            }
                        ]
                    },
                    {
                        "title": "Orchestra",
                        "items": [
                            {
                                "title": "Ninereeds the Dragon (2019)",
                                "text": "The inaugural performance.",
                                "youtube": "https://www.youtube.com/embed/EKzVCDBLjeA"
                            },
                            {
                                "title": "Mort Suite - Orchestra (2019)",
                                "youtube": "https://www.youtube.com/embed/-8qnygIAFfY"
                            }
                        ]
                    }
                ]
            },
            {
                "shortTitle": "Make",
                "title": "Personal projects",
                "subtitle": "Things I make on weekends.",
                "thumb": "make_100x100.png",
                "subsections": [
                    {
                        "title": "Browser Based Experiments",
                        "items": [
                            {
                                "title": "Practice: The Art of Making Mistakes Visible (my book)",
                                "link": "http://www.amazon.com.au/dp/B0BYGWP8DC"
                            },
                            {
                                "title": "Foggy Walk - PuzzleScript Prototype",
                                "link": "https://foggywalk.netlify.app/"
                            },
                            {
                                "title": "Thirst! - Puzzle Game Prototype",
                                "link": "https://upbeat-wright-12f44d.netlify.app/"
                            },
                            {
                                "title": "Bounce! - Puzzle Game Prototype",
                                "link": "https://bouncepuzzle.netlify.app/"
                            },
                            {
                                "title": "Swarm Font",
                                "link": "https://nervous-franklin-156e46.netlify.app/"
                            },
                            {
                                "title": "Basic Boids",
                                "link": "https://romantic-hawking-4d7b82.netlify.app/"
                            },
                            {
                                "title": "The Garden of Cthulhu. Evolving alien plants.",
                                "link": "https://adoring-joliot-22743f.netlify.app/"
                            },
                            {
                                "title": "Where am I? Current Geolocation.",
                                "link": "https://relaxed-kirch-752c81.netlify.app/"
                            },
                            {
                                "title": "L-Systems with randomly generated rules.",
                                "link": "https://tender-swartz-75c580.netlify.app/"
                            },
                            {
                                "title": "Musical part arranger. Midi control export. (2021-08-15)",
                                "link": "https://arranger.netlify.app/"
                            },
                            {
                                "title": "Diffusion Reaction System",
                                "link": "https://banananinja.netlify.app/"
                            },
                            {
                                "title": "Startup Juice - Is your startup idea worthy?",
                                "link": "https://startupjuice.netlify.app/"
                            },
                            {
                                "title": "Play a game by playing a musical instrument",
                                "link": "https://journeyhome.netlify.app/"
                            },
                            {
                                "title": "Random number generator",
                                "link": "https://diceman.netlify.app/"
                            },
                            {
                                "title": "Game of life, but sound triggers starting cells.",
                                "link": "https://lifeofsound.netlify.app/"
                            },
                            {
                                "title": "Evolving strange cellular automata. Be the Goddess of Chaos.",
                                "link": "https://goddessofchaos.netlify.app/"
                            },
                            {
                                "title": "A big Conway's Game of Life",
                                "link": "https://life1080.netlify.app/"
                            },
                            {
                                "title": "Prototype Pencil Racer implementation",
                                "link": "https://pencilracer.netlify.app/"
                            },
                            {
                                "title": "Music Toy - Sound rains down like sand",
                                "link": "https://musicsand.netlify.app/"
                            }
                        ]
                    },
                    {
                        "title": "Building Blocks",
                        "text": "Elementary components that can be combined to make all kinds of things.",
                        "image": "PersonalBuildingBlocks.svg",
                        "items": [
                            {
                                "title": "Publish a basic web page",
                                "link": "https://basichtmlpublish.netlify.app/"
                            },
                            {
                                "title": "Publish a basic react page",
                                "link": "https://publishbasicreact.netlify.app/"
                            },
                            {
                                "title": "Publish a basic ionic app as a web page.",
                                "link": "https://ionichelloworld.netlify.app/"
                            },
                            {
                                "title": "Run a basic local express static web server.",
                                "link": "https://basicexpressserver.netlify.app/"
                            },
                            {
                                "title": "Run a static server + rest api with basic dice app that uses the api.",
                                "link": "https://basicrestdiceapi.netlify.app/"
                            },
                            {
                                "title": "Deploy a Node App to Heroku",
                                "link": "https://nodejs-dice.herokuapp.com/instructions"
                            },
                            {
                                "title": "Write data to an InfluxDB database in a docker container.",
                                "link": "https://writetoinfluxfromnode.netlify.app/"
                            },
                            {
                                "title": "Read data from an InfluxDB database in a docker container.",
                                "link": "https://readfrominfluxdb.netlify.app/"
                            },
                            {
                                "title": "C to WASM in HTML file via Javascript Hello World and Add.",
                                "link": "https://ctowasmtohtml.netlify.app/"
                            },
                            {
                                "title": "Geolocation in Ionic",
                                "link": "https://dazzling-wing-b42dbd.netlify.app/"
                            },
                            {
                                "title": "MQTT Server",
                                "link": "https://mqtt-buildingblock.netlify.app/"
                            }
                        ]
                    },
                    {
                        "title": "Generative Music",
                        "items": [
                            {
                                "title": "Geo Music - Carrickfergus",
                                "text": "Parts based on geographic location",
                                "youtube": "https://www.youtube.com/embed/Pa_e-40i3x0"
                            },
                            {
                                "title": "Drunken Conductor",
                                "text": "Orchestral improvisation",
                                "youtube": "https://www.youtube.com/embed/9BDSOKq072U"
                            },
                            {
                                "title": "Extended Version of \"Journey of the Hero\"",
                                "soundcloud": 1126766338
                            },
                            {
                                "title": "Ambient Pi",
                                "youtube": "https://www.youtube.com/embed/CzOkpgyHnFQ"
                            }
                        ]
                    },
                    {
                        "title": "Ambient Music",
                        "items": [
                            {
                                "title": "Ambient Spaceport at Midnight",
                                "soundcloud": 1015841698
                            }
                        ]
                    },
                    {
                        "title": "Nature Sound Recordings",
                        "items": [
                            {
                                "title": "Boudi National Park",
                                "soundcloud": 239525500
                            },
                            {
                                "title": "Maroochydore Surf",
                                "soundcloud": 130607400
                            },
                            {
                                "title": "Lagoon at Sunrise",
                                "soundcloud": 130229922
                            },
                            {
                                "title": "Lagoon after Sunrise",
                                "soundcloud": 130039719
                            },
                            {
                                "title": "Sunrise in the bush",
                                "soundcloud": 130038844
                            },
                            {
                                "title": "Twin Waters",
                                "soundcloud": 129698391
                            }
                        ]
                    }
                ]
            },
            {
                "shortTitle": "Perform",
                "title": "Performances",
                "subtitle": "Some musical performances I've been in.",
                "thumb": "perform_100x100.png",
                "hero_image": "perform_big.png",
                "subsections": [
                    {
                        "title": "Inner West Community Band",
                        "items": [
                            {
                                "title": "Flashback (2023)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLZLwxa0eXGMt6dHRLzszcxm5ZNGD1Gdop"
                            },
                            {
                                "title": "Ole (2022)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLZLwxa0eXGMtCo3y1WQ4eKWpne8ko8RBP"
                            },
                            {
                                "title": "Water (2022)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLZLwxa0eXGMvA_t425-C_1Nr1UealTUTN"
                            },
                            {
                                "title": "Americana (2022)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLZLwxa0eXGMtuSA0noJBqAWN7s9VAn8wN"
                            },
                            {
                                "title": "Fiesta! (2022)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLZLwxa0eXGMuNWPHwmrZVR6HqAEKt62Cv"
                            },
                            {
                                "title": "Strike Up the Band Concert (2021)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLZLwxa0eXGMtSg4RfJ8zfycn_15x5k8Oe"
                            },
                            {
                                "title": "10th Anniversary Concert (2021)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLZLwxa0eXGMsP3wsmFNJ6Gq_cHOYvI_QI"
                            },
                            {
                                "title": "IWCB Website",
                                "link": "https://iwcb.org.au/"
                            }
                        ]
                    },
                    {
                        "title": "FilmHarmonia Orchestra",
                        "items": [
                            {
                                "title": "Redemption (2022)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLc-R3Xjoy9Aj-f82_pxLaayfllN-jS7uL"
                            },
                            {
                                "title": "Unexpected Hero (2022)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLc-R3Xjoy9AjNfYPxxBMp2heQaajq-aiQ"
                            },
                            {
                                "title": "Stowaways (2022)",
                                "youtube": "https://www.youtube.com/embed/videoseries?list=PLc-R3Xjoy9AgskkS15oEhBt28EpVkcEVc"
                            },
                            {
                                "title": "TFO Website",
                                "link": "https://filmharmonia.com/"
                            }
                        ]
                    }
                ]
            },
            {
                "shortTitle": "Work",
                "title": "Work",
                "subtitle": "Linkedin",
                "thumb": "work_100x100.png",
                "link": "https://www.linkedin.com/in/simon-parker-05746054/"
            }
        ]
    },
    "layouts": [
        {
            "name": "Simple List",
            "link": "https://angry-lamarr-6e2f20.netlify.app/",
            "icon": "simple_list"
        },
        {
            "name": "Simple Tabs",
            "link": "https://vibrant-pare-7d9310.netlify.app/",
            "icon": "simple_tabs"
        },
        {
            "name": "Simple Side Menu",
            "link": "https://compassionate-sammet-210922.netlify.app/",
            "icon": "simple_side_menu"
        },
        {
            "name": "Quad Box",
            "link": "https://simonparker.netlify.app/",
            "icon": "quad_box"
        },
        {
            "name": "Alternating",
            "link": "https://gifted-northcutt-528d73.netlify.app/",
            "icon": "simple_alt"
        }
    ]
}
import { useIonViewDidEnter } from '@ionic/react';
import { useEffect, useState } from 'react';
import { Forest } from './FireModel';
import './SvgFire.css';

interface ContainerProps {
  width: number;
  height: number;
  initCount: number;
 }

 var g_Forest = new Forest(800 / 5, 450 /5);

const SvgFire: React.FC<ContainerProps> = ({width, height, initCount}) => {
  const TREE_SIZE : number = 5;
  const nW : number = Math.floor(width / TREE_SIZE);
  const nH : number = Math.floor(height / TREE_SIZE);
  const [trees, setTrees] = useState(g_Forest.trees);

  function stepFire()
  {
    g_Forest.updateSize(nW, nH);
    g_Forest.step();
    setTrees(g_Forest.trees);
  }

  useEffect(() => {
    let intervalTimer = setInterval(stepFire, 50);
    return () => clearInterval(intervalTimer);
  });

  useEffect(() => {
    g_Forest.init(0.61);
  }, [initCount]);

  function makeRects()
  {
    return trees.map((cols, iX) => {
      return trees[iX].map((state, iY) => {
        let stateName = `state${trees[iX][iY]}`;
        let id = `${iX}-${iY}`;
        let r = <rect key={id} 
                  className={stateName} 
                  width={TREE_SIZE} 
                  height={TREE_SIZE} 
                  x={iX * TREE_SIZE} 
                  y = {iY * TREE_SIZE} 
                  onMouseOver={() => g_Forest.burn(iX, iY)}/>
        return r;        
      })
    })
  }

  const rects = makeRects();

  return (
    <g>
      {rects}
    </g>
  );
};

export default SvgFire;

import { IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar, IonLabel, IonList } from '@ionic/react';
import { Redirect, useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { g_SiteContent, HeroItem, HeroSection, HeroSubsection, Layout } from '../data/contentTypes';
import './Home.css';

const sections: HeroSection[] = g_SiteContent.Hero.sections;


const Section: React.FC = () => {
  const params = useParams<{ id: string }>();
  const index = parseInt(params.id);
  const section : HeroSection = sections[index];



  if ("link" in section)
  {
    return (
      <h1>Nothing to see here</h1>
    )
  }
  function itemView(item : HeroItem)
  {
    if ("youtube" in item)
    {
      return (
        <IonItem  key={item.title}>
        <IonLabel>
          <h4>{item.title}</h4>
          <p>{item.text}</p>
          <iframe width="560" height="315" src={item.youtube} title={item.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </IonLabel>
      </IonItem>
      );
    }
    else if ("soundcloud" in item)
    {
      const s = "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" + item.soundcloud + "&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true";

      return (
        <IonItem  key={item.title}>
        <IonLabel>
          <h4>{item.title}</h4>
          <p>{item.text}</p>
          <iframe width="100%" height="166" scrolling="no" frameBorder="no" allow="autoplay" src={s}></iframe>
          <div className="soundcloud">
            <a href="https://soundcloud.com/engineeringsimon" title="Engineering Simon" target="_blank" className="soundcloud">Engineering Simon</a>
             · 
            <a href="https://soundcloud.com/engineeringsimon/journey-of-the-hero" title="Journey Of The Hero" target="_blank" className="soundcloud">Journey Of The Hero</a>
          </div>
        </IonLabel>
      </IonItem>
      );
    }
    else if ("link" in item)
    {
      return (
        <IonItem href={item.link} key={item.title}>
          <IonLabel>
            <h4>{item.title}</h4>
            <p>{item.text}</p>
          </IonLabel>
      </IonItem>
      );      
    }

    return (
      <p>Non-youtube, non-link</p>
    );
    
  }


  function makeSubsection(subsection: HeroSubsection)
  {
    return (
      <>
        <h2>{subsection.title}</h2>
        {(subsection.image) ? <img src={"assets/img/" + subsection.image} /> : ""}
        
        {subsection.items.map(itemView)}
      </>
    );
  }

  return (
    <IonContent fullscreen>
      <h1>{sections[index].title}</h1>
      <Link to="/home">by {g_SiteContent.Hero.name}</Link>

      <IonList>
      {(section.subsections) ? section.subsections.map(makeSubsection) : ""}
      </IonList>

      <IonTitle>Layouts</IonTitle>
      <IonList>
        {g_SiteContent.layouts.map(layout => (
         <IonItem>
           <IonLabel>
             <a href={layout.link}>{layout.name}</a>
           </IonLabel>
        </IonItem>
        ))}
      </IonList>

    </IonContent>
    
  );
};

export default Section;

import { useIonViewDidEnter } from '@ionic/react';
import { AnyRecord } from 'dns';
import React, { useEffect, useState } from 'react';
import './SimonIcon.css';

interface ContainerProps {
  icon: string;
}


const SimonIcon: React.FC<ContainerProps> = ({ icon }) => {

  function makeIconItem(iconId: string) {
    switch (iconId) {
      case "simple_list":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="161px" height="161px" viewBox="-0.5 -0.5 161 161">
            <defs />
            <g>
              <rect className="border" x="0" y="0" width="160" height="160" pointer-events="all" />
              <g className="foreground">
                <rect x="40" y="30" width="80" height="20" />
                <ellipse cx="40" cy="40" rx="10" ry="10" />
                <ellipse cx="120" cy="40" rx="10" ry="10" />
                <rect x="40" y="70" width="80" height="20" />
                <ellipse cx="40" cy="80" rx="10" ry="10" />
                <ellipse cx="120" cy="80" rx="10" ry="10" />
                <rect x="40" y="110" width="80" height="20" />
                <ellipse cx="40" cy="120" rx="10" ry="10" />
                <ellipse cx="120" cy="120" rx="10" ry="10" />
              </g>
            </g>
          </svg>
        );
      case "simple_tabs":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="161px" height="161px" viewBox="-0.5 -0.5 161 161">
            <defs />
            <g>
              <rect className="border" x="0" y="0" width="160" height="160" pointer-events="all" />
              <g className="foreground">
                <rect x="40" y="30" width="80" height="20" />
                <ellipse cx="40" cy="40" rx="10" ry="10" />
                <ellipse cx="120" cy="40" rx="10" ry="10" />
                <rect x="40" y="70" width="80" height="20" />
                <ellipse cx="40" cy="80" rx="10" ry="10" />
                <ellipse cx="120" cy="80" rx="10" ry="10" />
                <rect x="70" y="110" width="20" height="20" />
                <ellipse cx="40" cy="120" rx="10" ry="10" />
                <path d="M 110 110 L 130 120 L 110 130 Z" stroke-miterlimit="10" transform="rotate(-90,120,120)" />
              </g>
            </g>
          </svg>
        );

      case "simple_side_menu":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="161px" height="161px" viewBox="-0.5 -0.5 161 161">
            <defs />
            <g>
              <rect className="border" x="0" y="0" width="160" height="160" pointer-events="all" />
              <g className="foreground">
                <rect x="20" y="30" width="20" height="100" />
                <rect x="60" y="30" width="70" height="20" />
                <ellipse cx="60" cy="40" rx="10" ry="10" />
                <ellipse cx="130" cy="40" rx="10" ry="10" />
                <rect x="60" y="70" width="70" height="20" />
                <ellipse cx="60" cy="80" rx="10" ry="10" />
                <ellipse cx="130" cy="80" rx="10" ry="10" />
                <rect x="60" y="110" width="70" height="20" />
                <ellipse cx="60" cy="120" rx="10" ry="10" />
                <ellipse cx="130" cy="120" rx="10" ry="10" />
              </g>
            </g>
          </svg>
        );
      case "quad_box":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="161px" height="161px" viewBox="-0.5 -0.5 161 161">
            <defs />
            <g>
              <rect className="border" x="0" y="0" width="160" height="160" pointer-events="all" />
              <g className="foreground">
                <rect x="30" y="90" width="40" height="40" />
                <rect x="30" y="30" width="40" height="40" />
                <rect x="90" y="30" width="40" height="40" />
                <rect x="90" y="90" width="40" height="40" />
              </g>
            </g>
          </svg>
        );
      case "magazine":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="161px" height="161px" viewBox="-0.5 -0.5 161 161" >
            <defs />
            <g>
              <rect className="border" x="0" y="0" width="160" height="160" fill="#ffffff" stroke="#000000" pointer-events="all" />
              <g className="foreground">
                <rect x="80" y="80" width="50" height="50" />
                <rect x="30" y="30" width="60" height="40" />
                <rect x="100" y="30" width="30" height="40" />
                <rect x="30" y="80" width="40" height="20" />
                <rect x="30" y="110" width="40" height="20" />
              </g>
            </g>
          </svg>
        );
      case "simple_alt":
        return (
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="161px" height="161px" viewBox="-0.5 -0.5 161 161">
            <defs />
            <g>
              <rect className="border" x="0" y="0" width="160" height="160" />
              <g className="foreground">
                <rect x="70" y="85" width="60" height="20" />
                <rect x="30" y="25" width="30" height="20" />
                <rect x="70" y="25" width="60" height="20" />
                <rect x="100" y="55" width="30" height="20" />
                <rect x="30" y="85" width="30" height="20" />
                <rect x="30" y="55" width="60" height="20" />
                <rect x="100" y="115" width="30" height="20" />
                <rect x="30" y="115" width="60" height="20" />
              </g>
            </g>
          </svg>
        );
      default:
        return (
          <p>Dunno</p>
        )
    }
  }

  return (
    <g>
      {makeIconItem(icon)}
    </g>
  );
};

export default SimonIcon;
